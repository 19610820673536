/* eslint-disable */
export default class BrockmanAds {
  constructor(adClass, options, cohorts) {
    this.adSelector = adClass;
    this.adClass = adClass.replace('.', '');

    const sizeMappingDefaults = {
      'portable-leaderboard': [
        {
          viewport: [1260, 300],
          ad_sizes: [
            [728, 90],
            [970, 250],
            [1260, 110],
            [1260, 250],
          ],
        },
        {
          viewport: [980, 300],
          ad_sizes: [
            [728, 90],
            [970, 250],
          ],
        },
        { viewport: [750, 300], ad_sizes: [728, 90] },
        { viewport: [340, 300], ad_sizes: [320, 50] },
        { viewport: [0, 0], ad_sizes: [] },
      ],
      'portable-fishbowl': [
        {
          viewport: [980, 300],
          ad_sizes: [
            [320, 400],
            [728, 90],
            [970, 250],
          ],
        },
        {
          viewport: [750, 300],
          ad_sizes: [
            [320, 400],
            [728, 90],
          ],
        },
        { viewport: [0, 0], ad_sizes: [] },
      ],
      'portable-mpu-leader': [
        {
          viewport: [980, 300],
          ad_sizes: [
            [300, 250],
            [728, 90],
            [970, 250],
          ],
        },
        {
          viewport: [750, 300],
          ad_sizes: [
            [300, 250],
            [728, 90],
          ],
        },
        { viewport: [0, 0], ad_sizes: [] },
      ],
    };

    const defaults = {
      gamId: '43340684',
      customVariables: '',
      baseUri: '',
      size_mappings: sizeMappingDefaults,
      piiCheck: false,
      lazyload_selector: '.lazyload',
      lazyloadDefaultSpacer: 800,
      viewportMultiplierMobile: 1,
      viewportMultiplierDesktop: 1,
      showNonPersonalisedAds: 'false',
      device: 'mobile',
      floorPrices: {},
      runUAM: false,
      runPrebid: false,
      permutiveTimeout: 0,
      biddingTimeout: 2000,
      prebidTimeout: 1500,
      pubUAM: 'a06ac1f9-0844-4cf7-81fe-f8ce4a7d7943',
      prebidLib: 'https://cdn.gamer-network.net/2020/ads/prebid3.9.0.js',
      prebidConfig: {},
      runAnonymised: false,
      teadsIdOverride: '',
      adRenderedCallback() {},
      insertInto: '',
      insertTopMargin: 0,
      stickySidebar: false,
      stickyViewports: 3,
      inlineInto: '',
      inlineBetweenTextOnly: false,
      inlineAvoidBbwApester: false,
      desktopInlineInto: '.article_body_content',
      advertHeader: 'Advertisement',
      firstMobileAd: '',
      fixMobileAdHeight: false,
      dynamicAd: 'AE_MPU_1',
      lowLeaderboard: 'AE_LB2',
      dynamicAdDesktop: '',
      stickyAd: '',
      initialized: false,
      childPublisher: '',
      testingCohort: '',
      ownedSite: true,
      runAutoSlotsEverywhere: false,
      apesterToken: '',
      mobileInReadId: '',
      useArticleVideoLayout: false,
      articleType: '',
      articlePublishedDate: '',
      primisPlaylistPlacementId: '',
      primisStyling: false,
      videoPlaylist: false,
      videoPlaylistArticles: false,
      videoPlaylistTitle: '',
      videoPlaylistID: '',
      videoPlaylistGamesList: {},
      bbwPublication: 'gamernetwork',
      bbwAutoplay: '',
      lazyloadSpacerMobile: 800,
      apesterDesktop: '',
      apesterDesktopOnly: false,
      runQuokkster: false,
      articleAutoPoll: true,
      articleVideoAutoInject: true,
      inlineAdsConfig: {
        includeHeaderInCalc: true,
        minAdvertSpacing: 0,
        lastAdTollerance: 1,
      },
      countryCode: '',
      refreshAdsConfig: {
        enabled: false,
        maxRefreshes: 10,
        refreshableAdvertIds: [220082044, 78946204, 72863404],
        refreshDirect: false,
        directMobileRate: 30000,
        overrideRate: 30000,
      },
      pubstackTagId: '',
      mobileHalfpagePosition: 0,
      debug: new URLSearchParams(document.location.search).get('ads-debug') === 'true',
      mobileInterstitial: '',
      desktopSticky: '',
      appendSidebarAds: true,
      userExternalUuid: '',
      refreshFixedBillboard: true,
      gdprApplies: true,
    };

    this.adsForRefresh = { };

    if (cohorts !== undefined && cohorts.length > 1) {
      const chosenCohort = this.chooseCohort(cohorts);

      Object.keys(chosenCohort).forEach((prop) => {
        options[prop] = chosenCohort[prop];
      });

      const metaEl = document.createElement('meta');
      metaEl.name = 'pbstck_context:pbstck_ab_test';
      metaEl.content = chosenCohort.testingCohort;
      document.getElementsByTagName('head')[0].appendChild(metaEl);
    }

    const opts = { ...defaults, ...options };

    Object.keys(defaults).forEach((prop) => {
      this[prop] = opts[prop];
    });

    if (this.device === 'mobile') {
      this.lazyloadDefaultSpacer = this.lazyloadSpacerMobile;
    }

    const { defaultFloorprice, usFloorprice, ukFloorprice, aucaFloorprice, euFloorprice } = this.floorPrices;

    let floorPrice = defaultFloorprice;
    switch (this.countryCode) {
      case 'US':
        floorPrice = usFloorprice || 0;
        break;
      case 'GB':
      case 'UK':
        floorPrice = ukFloorprice || 0;
        break;
      case 'AU':
      case 'CA':
        floorPrice = aucaFloorprice || 0;
        break;
      case 'DE':
      case 'NL':
      case 'SE':
      case 'AT':
      case 'FR':
      case 'NO':
      case 'CH':
      case 'IE':
      case 'FI':
      case 'DK':
      case 'BE':
        floorPrice = euFloorprice || 0;
        break;
      default:
        floorPrice = defaultFloorprice || 0;
    }

    if (this.runPrebid && floorPrice) {
      this.floor_settings = {
        currency: 'GBP',
        modelVersion: 'Geo Floors',
        schema: {
          fields: ['mediaType', 'size'],
        },
        values: {
          'banner|*': floorPrice,
        },
      };
    }

    this.GAMFloor = floorPrice;
  }

  init() {
    this.loadGAM();
    this.initialized = true;
    this.registerDesktopBillboard();
  }

  chooseCohort(cohorts) {
    // Get total of cohohort split
    const random = Math.random() * cohorts.map((cohort) => cohort.split).reduce((a, c) => a + c);
    let sumOfSplit = 0;
    let testedCohort;

    for (let i = 0; i < cohorts.length; i += 1) {
      testedCohort = cohorts[i];
      sumOfSplit += testedCohort.split;
      if (sumOfSplit > random) break;
    }

    testedCohort.config.testingCohort = `${testedCohort.name}-${testedCohort.split}`;

    return testedCohort.config;
  }

  urlToArray(url) {
    const request = new Map();
    const pairs = url.substring(url.indexOf('?') + 1).split('&');
    for (let i = 0; i < pairs.length; i++) {
      if (!pairs[i]) continue;
      const pair = pairs[i].split('=');
      request.set(decodeURIComponent(pair[0]), decodeURIComponent(pair[1]));
    }
    return request;
  }

  isEmail(email) {
    const regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  }

  inreadConditions(position, replacingPostion = 2) {
    const noInRead = this.mobileInReadId === '';
    const correctPlacement = position === replacingPostion;

    if (noInRead || !correctPlacement) {
      return false;
    }

    return true;
  }

  videoPlaylistConditions() {
    if (this.useArticleVideoLayout) {
      return false;
    }

    const enableVideoPlaylist = this.videoPlaylist === true || this.primisPlaylistPlacementId !== '';
    const articleTypeNews = this.articleType === 'news' && !this.runAutoSlotsEverywhere;

    let articleOldContent = false;

    if (this.articlePublishedDate) {
      const articlePublishDate = new Date(this.articlePublishedDate);
      articleOldContent = articlePublishDate.getFullYear() < 2021;
    }

    if (this.customVariables.tags && this.customVariables.tags.some((tag) => tag === 'digital-foundry')) {
      return false;
    }

    const existingVideoEmbed = document.querySelectorAll('script[data-cookies-src*=bbvms], script[src*=bbvms\\.com], script[src*=live\\.primis\\.tech], script[data-cookies-src*=live\\.primis\\.tech]').length;

    if (existingVideoEmbed) {
      return false;
    }

    if (!enableVideoPlaylist || !this.articleVideoAutoInject || articleTypeNews || (!articleOldContent && this.videoPlaylistArticles && !this.runAutoSlotsEverywhere) || !this.isBrandSafe() || (!this.bbwPublication && !this.primisPlaylistPlacementId) || (!this.bbwAutoplay && !this.primisPlaylistPlacementId)) {
      return false;
    }

    return true;
  }

  surveyConditions(isDesktop, existingElementQueryString = '.apester-media') {
    const noApesterToken = this.apesterToken === '';
    const articleTypeNews = this.articleType === 'news';

    const desktopOnly = this.apesterDesktopOnly;
    let existingPoll = false;
    const existingElement = document.querySelectorAll(existingElementQueryString).length;

    // Check for manual Quokkster on page
    const manualQuokksterPoll = document.querySelector('.poll_wrapper[data-fixed="true"]');
    if(manualQuokksterPoll) {
      existingPoll = true;
    }

    // if manual apester poll on page send analytics event
    if(existingElement) {
      if (this.testingCohort.includes('testApester') || this.testingCohort.includes('testQuokkster')) {
        this.sendAnalyticsEvent('apester', 'loaded');
      }
    }

    // If manual Apester or Quokkster on page then return false
    if (existingElement || existingPoll) {
      if (this.debug) {
        console.log(`%cPOLL ALREADY ON PAGE`, 'background: none; color: red; padding: 4px 8px');
      }
      return false;
    }

    // If article is news or mobile page only showing Apester on desktop or not a brand safe page or we're not auto placing polls then return false.
    if ( articleTypeNews || (!isDesktop && desktopOnly) || !this.isBrandSafe() || !this.articleAutoPoll) {
      if (this.debug) {
        console.log(`%cNO POLL INJECTION FOR THIS ARTICLE`, 'background: none; color: red; padding: 4px 8px');
      }
      return false;
    }

    // If no Apester token and Quokkster turned off then return false
    if (noApesterToken && !this.runQuokkster) {
      if (this.debug) {
        console.log(`%cNO POLL SETTINGS`, 'background: none; color: red; padding: 4px 8px');
      }
      return false;
    }

    if (this.debug) {
      console.log(`%cPOLL CONDITIONS MET`, 'background: none; color: green; padding: 4px 8px');
    }

    return true;
  }

  getCookie(name) {
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookieArray = decodedCookie.split(';');
    return cookieArray.find((cookie) => {
      const [cookieKey, ] = cookie.trim().split('=');
      return cookieKey === name;
    });
  }

  setPublisherProvidedIdCookie(id) {
    const expiryDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1));

    if (id) {
      document.cookie = `brockmanPPID=${id};expires=${expiryDate.toUTCString()};`
      return;
    }

    const consentUUIDCookie = this.getCookie('consentUUID');
    if (consentUUIDCookie) {
      const consentUUID = consentUUIDCookie.trim().split('=')[1];
      if (consentUUID) {
        document.cookie = `brockmanPPID=${consentUUID};expires=${expiryDate.toUTCString()};`
      }
    }
  }

  getPublisherProvidedId() {
    const ppidCookie = this.getCookie('brockmanPPID');

    if (ppidCookie) {
      const ppidCookieValue = ppidCookie.trim().split('=')[1];

      if (this.userExternalUuid && this.userExternalUuid !== ppidCookieValue) {
        if (this.userExternalUuid !== ppidCookieValue) {
          this.setPublisherProvidedIdCookie(this.userExternalUuid);
        }
        return this.userExternalUuid;
      }

      return ppidCookieValue;
    }

    this.setPublisherProvidedIdCookie();
    return null;
  }

  loadGAM() {

    if (this.pubstackTagId) {
      const pubStackScript = document.createElement('script');
      pubStackScript.type = 'text/javascript';
      pubStackScript.async = true;
      pubStackScript.src = `https://boot.pbstck.com/v1/tag/${  this.pubstackTagId}`;
      document.getElementsByTagName('head')[0].appendChild(pubStackScript);
    }

    this.loadTeads();

    window.googletag = window.googletag || { cmd: [] };

    const gads = document.createElement('script');
    gads.async = true;
    gads.type = 'text/javascript';

    const useSSL = document.location.protocol === 'https:';
    gads.src = `${useSSL ? 'https:' : 'http:'}//securepubads.g.doubleclick.net/tag/js/gpt.js`;
    const node = document.getElementsByTagName('script')[0];
    node.parentNode.insertBefore(gads, node);

    // Load APS library
    if (this.runUAM) {
      !(function (a9, a, p, s, t, A, g) {
        if (a[a9]) return;
        function q(c, r) {
          a[a9]._Q.push([c, r]);
        }
        a[a9] = {
          init() {
            q('i', arguments);
          },
          fetchBids() {
            q('f', arguments);
          },
          setDisplayBids() {},
          targetingKeys() {
            return [];
          },
          _Q: [],
        };
        A = p.createElement(s);
        A.async = !0;
        A.src = t;
        g = p.getElementsByTagName(s)[0];
        g.parentNode.insertBefore(A, g);
      })('apstag', window, document, 'script', '//c.amazon-adsystem.com/aax2/apstag.js');

      const schainNode = !this.ownedSite
        ? [
            {
              asi: 'gamer.network', // Populate with the canonical domain of the advertising system where the seller.JSON file is hosted.
              sid: '22392450815', // The identifier associated with the seller or reseller account within your advertising system
              hp: 1, // 1 or 0, whether this node is involved in the payment flow
            },
          ]
        : [];

      let amazonInitParams = {
          pubID: this.pubUAM,
          adServer: 'googletag',
          simplerGPT: false,
          params: {
            us_privacy: ''
          },
          gdpr: {
            cmpTimeout: 5000
          },
          schain: {
            complete: 1, // Integer 1 or 0 indicating if all preceding nodes are complete
            ver: '1.0', // Version of the spec used
            nodes: schainNode,
          }
      }
      if (window.__uspapi) {
          window.__uspapi('getUSPData', 1, function(res) {
              if (res.uspString) {
                  amazonInitParams.params = { us_privacy: res.uspString }
              }
              apstag.init(amazonInitParams);
          });
      } else {
        apstag.init(amazonInitParams);
      }

    }

    // Load Prebid
    if (this.runPrebid) {
      const prebid_script = document.createElement('script');
      prebid_script.async = true;
      prebid_script.type = 'text/javascript';

      prebid_script.src = this.prebidLib;
      node.parentNode.insertBefore(prebid_script, node);
    }

    this.urlArray = this.urlToArray(window.location.search);

    for (const [key, value] of this.urlArray.entries()) {
      if (this.isEmail(value)) {
        this.piiCheck = true;
      }
    }

    if (!this.piiCheck) {
      const fixedBillboard = document.querySelector('.fixed-billboard');
      const isDesktop = this.device === 'desktop' || this.device === 'hd';

      if (isDesktop && !fixedBillboard) {
        if (this.insertInto && document.querySelectorAll(this.insertInto).length > 0) {
          this.insertCounter = 0;
          this.removeOverflowingSidebarAds();
          this.insertAds();
        }
        if (this.desktopSticky) {
          this.appendStickyAd(this.desktopSticky, '728x90,970x90,980x90');
        }
        this.inlineComUnitDesktop();
      } else if (isDesktop && fixedBillboard) {
        if (this.desktopInlineInto && document.querySelectorAll(this.desktopInlineInto).length > 0) {
          this.inlineCounter = 0;
          this.inlineAdsDesktop();
        }
        if (this.desktopSticky) {
          this.appendStickyAd(this.desktopSticky, '728x90,970x90,980x90');
        }
      } else {
        if (this.inlineInto && document.querySelectorAll(this.inlineInto).length > 0) {
          this.inlineCounter = 0;
          this.inlineAds();
        }
        if (this.stickyAd) {
          this.appendStickyAd(this.stickyAd, '325x100,320x100,320x50,325x50,120x30');
        }
      }
      const ads = document.querySelectorAll(this.adSelector);
      this.createAds(ads);
      this.lazyloadAds();
    }
  }

  loadTeads() {
    window.teads_analytics = window.teads_analytics || {};
    window.teads_analytics.analytics_tag_id = this.teadsIdOverride || 'PUB_21458';
    window.teads_analytics.share = window.teads_analytics.share || function() {
      ;(window.teads_analytics.shared_data = window.teads_analytics.shared_data || []).push(arguments)
    };

    const teadsScript = document.createElement('script');
    teadsScript.type = 'text/javascript';
    teadsScript.async = true;
    teadsScript.src = 'https://a.teads.tv/analytics/tag.js';
    document.getElementsByTagName('head')[0].appendChild(teadsScript);
  }

  createAds(ads) {
    const zones = [];
    const slots = [];

    const prebid_units = [];

    ads.forEach((adUnit) => {
      if (!adUnit.hidden) {
        let { gamId } = this;

        if (this.childPublisher) {
          gamId += `,${this.childPublisher}`;
        }

        const { dfpId } = adUnit.dataset;
        const zoneId = adUnit.id;
        let dimensions = '';

        if (adUnit.dataset.dfpSizes == 'fluid') {
          dimensions = 'fluid';
        } else {
          dimensions = this.getDimensions(adUnit);
        }

        const ad_targeting = adUnit.dataset.dfpTargeting;
        const outofpage = adUnit.dataset.dfpOutofpage;
        const collapse = adUnit.dataset.dfpCollapse;
        const responsive = adUnit.dataset.dfpResponsive;
        const refresh = adUnit.dataset.dfpRefresh;
        const sizeMappings = this.size_mappings;
        let interstitialSlot;
        zones.push(zoneId);

        if (this.prebidConfig[this.device] && this.prebidConfig[this.device][dfpId]) {
          const ad_config = this.prebidConfig[this.device][dfpId];
          ad_config.code = zoneId;
          ad_config.floors = this.floor_settings;
          ad_config.pubstack.tags = [`floor: ${this.GAMFloor}`];
          prebid_units.push(ad_config);
        }

        // Define ad slot in DFP
        googletag.cmd.push(() => {
          if (outofpage) {
            slots[zoneId] = googletag.defineOutOfPageSlot(`/${gamId}/${dfpId}`, zoneId).addService(googletag.pubads());
          } else {
            slots[zoneId] = googletag
              .defineSlot(`/${gamId}/${dfpId}`, dimensions, zoneId)
              .addService(googletag.pubads());
          }

          if(this.mobileInterstitial && this.device === 'mobile') {
            interstitialSlot = googletag.defineOutOfPageSlot(`/${gamId}/${this.mobileInterstitial}`, googletag.enums.OutOfPageFormat.INTERSTITIAL);
          }

          if (interstitialSlot) {
            interstitialSlot.addService(googletag.pubads());
          }

          // Ad slot specific targeting
          if (ad_targeting) {
            const targetingGroups = ad_targeting.split(',');

            targetingGroups.forEach(function (group) {
              const targetingSet = group.split('=');
              slots[zoneId].setTargeting(targetingSet[0], targetingSet[1]);
            });
          }

          if (collapse == 'true') {
            slots[zoneId].setCollapseEmptyDiv(true);
          }

          // Map responsive sizes
          if (responsive && sizeMappings[responsive]) {
            const map = googletag.sizeMapping();
            sizeMappings[responsive].forEach(function (sizeMap) {
              map.addSize(sizeMap.viewport, sizeMap.ad_sizes);
            });

            slots[zoneId].defineSizeMapping(map.build());
          }

        });
      }
    });

    this.checkNonPersonalisedAds();

    if (this.customVariables) {
      Array.from(Object.entries(this.customVariables)).forEach((target) => {
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting(target[0], target[1]);
        });
      });
    }

    if (!this.customVariables.url) {
      googletag.cmd.push(() => {
        googletag.pubads().setTargeting('url', window.location.pathname.substring(0, 40));
      });
    }

    const queryString = new URLSearchParams(window.location.search);
    const testPage = (queryString && queryString.get) ? queryString.get('special') : false;
    if (testPage) {
      googletag.cmd.push(() => {
        googletag.pubads().setTargeting('special', testPage);
      });
    }

    if (this.testingCohort) {
      googletag.cmd.push(() => {
        googletag.pubads().setTargeting('testingCohort', this.testingCohort);
        googletag.pubads().setTargeting('pbstck_ab_test', this.testingCohort);
      });
    }

    if (this.GAMFloor) {
      googletag.cmd.push(() => {
        googletag.pubads().setTargeting('floor', this.GAMFloor.toString());
      });
    }

    if (this.runAnonymised) {
      googletag.cmd.push(() => {
        googletag.pubads().setTargeting('anonymised', localStorage.getItem("cohort_ids") ? JSON.parse(localStorage.getItem("cohort_ids")) : []);
      });
    }

    // If an override ad refresh rate is passed then refresh using the ZD method
    const isPreview = queryString.has('google_preview');
    if(this.refreshAdsConfig.overrideRate > 0) {
      googletag.cmd.push(() => {
        googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          const { slot } = event;
          const slotElId = slot.getSlotElementId();
          // Log when an ad slot was rendered
          this.adsForRefresh[slotElId] = {'renderedAt': Math.floor(Date.now() / 1000)};
          if (this.debug) {
            console.log(`%c${slotElId} SLOT RENDERED AT: ${this.adsForRefresh[slotElId].renderedAt}`, 'background: none; color: green; padding: 4px 8px');
          }
        });
        googletag.pubads().addEventListener('impressionViewable', (event) => {
          const { slot } = event;
          const slotElId = slot.getSlotElementId();
          const responseInformation = slot.getResponseInformation();
          if (this.debug) {
            console.log(`%c${slotElId} SLOT VIEWABLE WITH ADVERTISER ID ${responseInformation.advertiserId}`, 'background: none; color: green; padding: 4px 8px');
          }
          // Don't refresh if URL has google_preview for ad testing
          if (this.refreshAdsConfig.enabled && !isPreview) {
            const adEl = document.getElementById(slotElId);
            let isDirectRefresh = false;
            // If refresh enabled check not a direct advertiser ID
            if (Number.isInteger(responseInformation.advertiserId) && !this.refreshAdsConfig.refreshableAdvertIds.includes(responseInformation.advertiserId)) {
              if(this.refreshAdsConfig.refreshDirect && adEl.dataset.dfpRefreshDirect === 'true') {
                if (this.debug) {
                  console.log(`%c${slotElId} CAN REFRESH DIRECT: ${responseInformation.advertiserId}`, 'background: none; color: green; padding: 4px 8px');
                }
                isDirectRefresh = true;
              } else {
                if (this.debug) {
                  console.log(`%c${slotElId} NOT REFRESHABLE ADVERTISER ID: ${responseInformation.advertiserId}`, 'background: none; color: red; padding: 4px 8px');
                }
                return;
              }
            }
            // Check when slot was rendered and work out refresh time from that point
            const now = Math.floor(Date.now() / 1000);
            if (!this.adsForRefresh[slotElId].renderedAt) { this.adsForRefresh[slotElId].renderedAt = now; }
            const slotHasBeenLoadedForSecs = (now - this.adsForRefresh[slotElId].renderedAt)
            let refreshInSecs = ((this.refreshAdsConfig.overrideRate / 1000) - slotHasBeenLoadedForSecs);
            if (refreshInSecs <= 0) { refreshInSecs = 0.001; }
            if (this.debug) {
              console.log(`%c${slotElId} CAN REFRESH IN: ${refreshInSecs}s`, 'background: none; color: green; padding: 4px 8px');
            }
            // Pass Prebid config for the ad
            const prebidAdUnits = [];
            if (this.prebidConfig[this.device] && this.prebidConfig[this.device][adEl.dataset.dfpId]) {
              const ad_config = this.prebidConfig[this.device][adEl.dataset.dfpId];
              ad_config.code = adEl.id;
              ad_config.floors = this.floor_settings;
              prebidAdUnits.push(ad_config);
            }
            // Set timeout for refresh
            setTimeout(() => {
              this.zdRefreshSlot(adEl, prebidAdUnits, slot, isDirectRefresh);
            }, refreshInSecs*1000);

          }
        });
      });

    }

    const inlineAdsDesktop = this.device === 'hd' && document.querySelector('.fixed-billboard');
    if (this.inlineInto && document.querySelectorAll(this.inlineInto).length > 0 || inlineAdsDesktop && this.desktopInlineInto && document.querySelectorAll(this.desktopInlineInto).length > 0) {
      if (!this.customVariables.ads_count) {
        const numberInlineAds = String(document.querySelectorAll('.inlinead').length);
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting('ads_count', numberInlineAds);
        });
      }
    }

    if (this.inlineInto && document.querySelectorAll(this.inlineInto).length > 0) {
      if (!this.customVariables.article_words) {
        function getText(el) {
          let textStr = '';
          for (let i = 0; i < el.childNodes.length; i++) {
            const node = el.childNodes[i];
            if (node.nodeType !== 8) {
              textStr += node.nodeType !== 1 ? node.nodeValue : getText(node);
            }
          }
          return textStr;
        }
        const articleBody = document.querySelector(`${this.inlineInto}`);
        if (articleBody) {
          let words = getText(articleBody);
          words = words.split(' ').filter((item) => item !== '' && item !== '\n' && item !== '\n\n');
          const numberArticleWords = String(Math.round(words.length / 10) * 10);
          googletag.cmd.push(() => {
            googletag.pubads().setTargeting('article_words', numberArticleWords);
          });
        }
      }

      if (!this.customVariables.article_images) {
        const numberInlineImages = String(document.querySelectorAll(`${this.inlineInto} img`).length);
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting('article_images', numberInlineImages);
        });
      }

      if (!this.customVariables.article_videos) {
        const articleVideoArray = [];
        articleVideoArray.push(
          ...Array.from(document.querySelectorAll('.article .video_wrapper > iframe, .article .video_wrapper > video')),
        );
        // Remove duplicate entries (in case more selectors need to be added to the above query)
        const numberInlineVideos = String([...new Set(articleVideoArray)].length);
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting('article_videos', numberInlineVideos);
        });
      }

      if (!this.customVariables.article_embeds) {
        const numberInlineEmbeds = String(document.querySelectorAll('.article iframe').length);
        googletag.cmd.push(() => {
          googletag.pubads().setTargeting('article_embeds', numberInlineEmbeds);
        });
      }
    }

    const { adRenderedCallback } = this;

    googletag.cmd.push(() => {
      googletag.pubads().enableSingleRequest();

      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        const slotName = event.slot.getSlotElementId();
        if (typeof adRenderedCallback === 'function') {
          adRenderedCallback.call(this, slotName, event);
          if (
            (this.stickyAd && slotName.includes(this.stickyAd)) ||
            (this.desktopSticky && slotName.includes(this.desktopSticky))
          ) {
            if (event.isEmpty) {
              document.getElementById('sticky_leaderboard').remove();
            } else {
              document
                .getElementById('sticky_leaderboard')
                .classList.add('allow_click');
              if (
                Number.isInteger(event.advertiserId) &&
                this.refreshAdsConfig.refreshableAdvertIds.includes(
                  event.advertiserId,
                )
              ) {
                document
                  .getElementById('sticky_leaderboard')
                  .classList.add('show_wrapper');
              }
            }
          }
        }
      });
      if (this.runUAM || this.runPrebid || (window.permutive && window.permutive.readyWithTimeout)) {
        googletag.pubads().disableInitialLoad();
      }
      const ppid = this.getPublisherProvidedId();
      if(ppid) {
        googletag.pubads().setPublisherProvidedId(ppid);
      }
      googletag.enableServices();
    });

    if (ads.length > 0) {
      if (this.runUAM || this.runPrebid) {
        if (window.permutive && window.permutive.readyWithTimeout && this.permutiveTimeout) {
          window.permutive.readyWithTimeout(() => {
            this.headerBidding(prebid_units);
          }, "realtime",  this.permutiveTimeout);
        } else {
          this.headerBidding(prebid_units);
        }
      } else if (window.permutive && window.permutive.readyWithTimeout && this.permutiveTimeout) {
          window.permutive.readyWithTimeout(() => {
            googletag.cmd.push(() => { googletag.pubads().refresh() });
            this.displayAds(zones);
          }, "realtime",  this.permutiveTimeout);
        } else {
          googletag.cmd.push(() => { googletag.pubads().refresh() });
          this.displayAds(zones);
        }
    }
  }

  headerBidding(prebid_units, lazyloadSlot) {
    let hb_parallel = false;
    const requestManager = {
      adserverRequestSent: false,
      aps: false,
      prebid: false,
    };

    if (this.runUAM && this.runPrebid) {
      hb_parallel = true;
    }

    if (this.runUAM) {
      const apsSlots = [];
      prebid_units.forEach((unit) => {
        if (unit) {
          const slotID = unit.code;
          const slotName = unit.pubstack && unit.pubstack.adUnitPath;
          const sizes = unit.mediaTypes && unit.mediaTypes.banner && unit.mediaTypes.banner.sizes;
          if (slotID && slotName && sizes) {
            apsSlots.push({ slotID, slotName, sizes })
          };
        }
      })

      if (lazyloadSlot) {
        const slotID = lazyloadSlot.getSlotElementId();
        const slotName = lazyloadSlot.getAdUnitPath();
        const sizes = this.getDimensions(document.getElementById(slotID));
        if (slotID && slotName && sizes) {
          apsSlots.push({ slotID, slotName, sizes })
        }
      }

      if (apsSlots.length) {
        googletag.cmd.push(() => {
          apstag.fetchBids({ slots: apsSlots }, function (bids) {
            apstag.setDisplayBids();
            requestManager.aps = true;
            biddersBack();
          });
        });
      }
    }

    if (this.runPrebid) {
      const prebidSetup = {
        buckets: [
          {
            precision: 2,
            max: 3,
            increment: 0.01,
          },
          {
            max: 9,
            increment: 0.02,
          },
          {
            max: 20,
            increment: 0.05,
          },
          {
            max: 30,
            increment: 0.1,
          },
          {
            max: 99,
            increment: 0.5,
          },
        ],
      };

      const consentManagement = {
        usp: {
          cmpApi: 'iab',
          timeout: 100,
        },
      };

      if (this.gdprApplies) {
        if (this.debug) {
          console.log(`%cAdd GDPR consent to Prebid`, 'background: none; color: green; padding: 4px 8px');
        }
        consentManagement.gdpr = {
          cmpApi: 'iab',
          timeout: 1000,
          defaultGdprScope: true,
          rules: [
            {
              purpose: 'storage',
              enforcePurpose: true,
              enforceVendor: true,
              vendorExceptions: ["permutive"]
            },
            {
              purpose: 'basicAds',
              enforcePurpose: true,
              enforceVendor: true,
              vendorExceptions: []
            }
          ]
        }
      };

      window.pbjs = window.pbjs || {};
      pbjs.que = pbjs.que || [];
      pbjs.que.push(() => {
        pbjs.aliasBidder('rubicon', 'shemedia');
        pbjs.bidderSettings = {
          standard: {
            storageAllowed: true,
          },
          shemedia: {
            bidCpmAdjustment : function(bidCpm, bid){
              return bidCpm * .48;
            }
          },
        };
        pbjs.setConfig({
          useBidCache: true,
          priceGranularity: prebidSetup,
          currency: {
            adServerCurrency: 'GBP',
            granularityMultiplier: 1,
            defaultRates: { GBP: { USD: 1.38, EUR: 1.17 } },
          },
          floors: {},
          enableTIDs: true,
          realTimeData: {
            auctionDelay: 50,
            dataProviders: [
              {
                name: 'permutive',
                waitForIt: true,
                params: {
                  acBidders: ['appnexus', 'pubmatic', 'openx']
                }
              }
            ]
          },
          userSync: {
            userIds: [
              {
                name: 'pubCommonId',
                storage: {
                  type: 'cookie',
                  name: `"_pubcid"`,
                  expires: 365,
                },
              },
              {
                name: 'id5Id',
                params: {
                  partner: 978,
                },
                storage: {
                  type: 'html5',
                  name: 'id5id',
                  expires: 90,
                  refreshInSeconds: 8 * 3600,
                },
              },
            ],
            auctionDelay: 50,
            filterSettings: {
              iframe: {
                bidders: '*',
                filter: 'include',
              },
            },
          }
        });
        pbjs.setConfig({ consentManagement });
        if (this.runAnonymised) {
          pbjs.setBidderConfig({
            bidders: ['appnexus'],
            config: {
              ortb2: {
                user: {
                  keywords: localStorage.getItem('cohort_ids') ? JSON.parse(localStorage.getItem('cohort_ids')).map(x=>`perid=${x}`).join(', ') : []
                }
              }
            }
          });
          pbjs.setBidderConfig({
            bidders: ['criteo', 'pubmatic'],
            config: {
              ortb2: {
                user: {
                  data: [
                    {
                      name: "anonymised.io",
                      ext: {
                        segtax: 1000
                      },
                      segment:
                      localStorage.getItem("cohort_ids") ? JSON.parse(localStorage.getItem("cohort_ids")).map(x => ({id: x})) : []
                    }
                  ]
                }
              }
            }
          });
        }
        pbjs.requestBids({
          adUnits: prebid_units,
          bidsBackHandler: () => {
            googletag.cmd.push(() => {
              pbjs.setTargetingForGPTAsync();
              requestManager.prebid = true;
              biddersBack();
            });
          },
          timeout: this.prebidTimeout,
        });
      });
    }
    function biddersBack() {
      if (hb_parallel === true) {
        if (requestManager.aps && requestManager.prebid) {
          sendAdserverRequest();
        }
      } else {
        sendAdserverRequest();
      }
    }

    function sendAdserverRequest() {
      if (requestManager.adserverRequestSent === true) {
        return;
      }
      requestManager.adserverRequestSent = true;
      googletag.cmd.push(function () {
        if (lazyloadSlot) {
          googletag.pubads().refresh([lazyloadSlot]);
        } else {
          googletag.pubads().refresh();
        }
      });
    }

    window.setTimeout(function () {
      sendAdserverRequest();
    }, this.biddingTimeout);
  }

  displayAds(zones) {
    zones.forEach(function (zone) {
      googletag.cmd.push(() => {
        googletag.display(zone);
      });
    });
  }

  getDimensions(adUnit) {
    const dimensions = [];
    const dimensionsData = adUnit.dataset.dfpSizes;

    if (dimensionsData) {
      const dimensionGroups = dimensionsData.split(',');

      dimensionGroups.forEach(function (size) {
        const dimensionSet = size.split('x');
        dimensions.push([parseInt(dimensionSet[0], 10), parseInt(dimensionSet[1], 10)]);
      });
    } else {
      dimensions.push([adUnit.width, adUnit.height]);
    }

    return dimensions;
  }

  checkNonPersonalisedAds() {
    if (this.showNonPersonalisedAds === 'true') {
      googletag.cmd.push(() => {
        googletag.pubads().setRequestNonPersonalizedAds(1);
        googletag.pubads().setTargeting('npa', 'true');
      });
    } else {
      googletag.cmd.push(() => {
        googletag.pubads().setRequestNonPersonalizedAds(0);
        googletag.pubads().setTargeting('npa', 'false');
      });
    }
  }

  lazyloadAds() {
    const lazyload_ads = document.querySelectorAll(this.lazyload_selector);

    const config = {
      rootMargin: `0px 0px ${this.lazyloadDefaultSpacer}px 0px`,
      threshold: 0,
    };

    if ('IntersectionObserver' in window) {
      const onLazyload = (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            observer.unobserve(entry.target);
            const lazyload_ad = entry.target;
            this.requestAd(lazyload_ad);
            lazyload_ad.classList.remove('lazyload');
            lazyload_ad.classList.add('lazyloaded');
            if (this.debug) {
              console.log(`%cClassic lazyload (${this.lazyloadDefaultSpacer}px) for: ${lazyload_ad.id}`, 'background: none; color: green; padding: 4px 8px');
            }
          }
        });
      };
      let observer = new IntersectionObserver(onLazyload, config);
      lazyload_ads.forEach((lazyload_ad) => {
        observer.observe(lazyload_ad);
      });
    }
  }

  isInViewport(ad, buffer) {
    const elementRect = ad.getBoundingClientRect();
    const elementTop = elementRect.top + window.pageYOffset;
    const elementBottom = elementRect.bottom + window.pageYOffset;
    const viewportTop = window.pageYOffset;
    const viewportBottom = viewportTop + window.innerHeight + buffer;
    return elementBottom > viewportTop && elementTop < viewportBottom;
  }

  zdRefreshSlot(ad, prebidUnits, lazyloadSlot, isDirectRefresh) {

    let refreshCount = parseInt(lazyloadSlot.getTargeting('refreshCount')[0], 10);
    if (isNaN(refreshCount)) {
      refreshCount = 0;
    }

    if (refreshCount >= this.refreshAdsConfig.maxRefreshes) {
      if (this.debug) {
        console.log(`Max refreshes (${this.refreshAdsConfig.maxRefreshes}) reached for ad slot: ${lazyloadSlot.getSlotElementId()}`, 'background: none; color: red; padding: 4px 8px', ad);
      }
      return;
    }

    const adRefresher = () => {
      lazyloadSlot.setTargeting('ad_refresh', 'true');
      lazyloadSlot.setTargeting('ad_refresh_rate', refreshCount);
      lazyloadSlot.setTargeting('refreshCount', refreshCount);
      if(isDirectRefresh) {
        lazyloadSlot.setTargeting('ad_refresh_direct', 'true');
      }

      if (this.runUAM || this.runPrebid) {
        const prebidUnit = prebidUnits.find((unit) => unit.code === ad.id);
        this.headerBidding(prebidUnit ? [prebidUnit]: [], lazyloadSlot);
      } else {
        googletag.pubads().refresh([lazyloadSlot]);
      }

      if (this.debug) {
        console.log(`%cREFRESHED: ${refreshCount}x`, 'background: none; color: green; padding: 4px 8px', ad);
      }

    };

    refreshCount += 1;
    adRefresher();

  }

  requestAd(ad, addAnother) {
    let sizes = '';
    if (ad.dataset.dfpSizes) {
      sizes = this.getDimensions(ad);
    } else {
      return;
    }

    let targeting = '';
    if (ad.dataset.dfpTargeting) {
      targeting = ad.dataset.dfpTargeting.split(',');
    }

    let collapse = '';
    if (ad.dataset.dfpCollapse) {
      collapse = ad.dataset.dfpCollapse === 'true';
    }

    const prebid_units = [];

    if (this.prebidConfig[this.device] && this.prebidConfig[this.device][ad.dataset.dfpId]) {
      const ad_config = this.prebidConfig[this.device][ad.dataset.dfpId];
      ad_config.code = ad.id;
      ad_config.floors = this.floor_settings;
      prebid_units.push(ad_config);
    }

    googletag.cmd.push(() => {
      let { gamId } = this;

      if (this.childPublisher) {
        gamId += `,${this.childPublisher}`;
      }

      const lazyloadSlot = googletag
        .defineSlot(`/${gamId}/${ad.dataset.dfpId}`, sizes, ad.id)
        .addService(googletag.pubads());
      if (targeting) {
        for (let k = 0; k < targeting.length; k++) {
          const rule = targeting[k].split('=');
          const key = rule[0];
          const value = rule[1];
          lazyloadSlot.setTargeting(key, value);
        }
      }
      if (collapse) {
        lazyloadSlot.setCollapseEmptyDiv(true);
      }

      if (this.runUAM || this.runPrebid) {
        if (window.permutive && window.permutive.readyWithTimeout && this.permutiveTimeout) {
          window.permutive.readyWithTimeout(() => {
            this.headerBidding(prebid_units, lazyloadSlot);
          }, "realtime",  this.permutiveTimeout);
        } else {
          this.headerBidding(prebid_units, lazyloadSlot);
        }
      } else if (window.permutive && window.permutive.readyWithTimeout && this.permutiveTimeout) {
          window.permutive.readyWithTimeout(() => {
            googletag.pubads().refresh([lazyloadSlot]);
          }, "realtime",  this.permutiveTimeout);
        } else {
          googletag.pubads().refresh([lazyloadSlot]);
        }

      if (addAnother) {
        googletag.pubads().addEventListener('slotRenderEnded', (event) => {
          if (addAnother == event.slot.getSlotElementId()) {
            const emptySpaceLeft = this.checkEmptySpace(this.insertInto);
            if (emptySpaceLeft) {
              this.appendAd(emptySpaceLeft);
            }
          }
        });
      }
    });
  }

  generateAd(position) {
    const adUnit = this.device === 'hd' && this.dynamicAdDesktop ? this.dynamicAdDesktop : this.dynamicAd;
    const adHTML = `
      <div class="insert_ad_column">
        <span class="advert_label">${this.advertHeader}</span>
        <div
          class="autoad_lazyload"
          data-dfp-id="${adUnit}"
          data-dfp-sizes="300x250, 300x600"
          data-dfp-collapse="true"
          data-dfp-targeting="lazyload=true,position=sidebar_${position}"
          id="div-ga-autoad_${adUnit}_${position}"
          data-dfp-refresh-direct="true"
        ></div>
      </div>
    `;

    return adHTML;
  }

  generateInlineAd(position) {
    const isMobile = this.device === 'mobile';
    const adUnit = isMobile ? this.dynamicAd : this.lowLeaderboard;
    let adClass = 'inlinead_lazyload';
    let lazyloadOn = 'true';
    let adSizes = '';
    let adRefreshDirect = 'false';
    if (isMobile) {
      adSizes = '300x250';
    } else {
      adSizes += ', 728x90, 960x160, 960x250, 970x250';
      if (position === 0) {
        adSizes += ', 970x500';
      }
    }
    let adId = adUnit;
    const adStyle = '';
    if (this.firstMobileAd && position === 1 && isMobile) {
      adClass = `${this.adClass} mpu`;
      lazyloadOn = 'false';
      adSizes = '300x250, 320x400';
      adId = this.firstMobileAd;
    } else {
      adRefreshDirect = "true";
    }
    if (this.fixMobileAdHeight && isMobile) {
      adClass = `${adClass} mobile_fixed_height`;
    }
    if (position === this.mobileHalfpagePosition && isMobile) {
      adClass = `${adClass} mobile_halfpage`;
      adSizes += ', 300x600';
    }
    const adHTML = `
      <span class="advert_label">${this.advertHeader}</span>
      <div
        class="${adClass}"
        style="${adStyle}"
        data-dfp-id="${adId}"
        data-dfp-sizes="${adSizes}"
        data-dfp-collapse="false"
        data-dfp-targeting="lazyload=${lazyloadOn},position=inline_${position}"
        id="div-ga-autoad_${adClass}_${position}"
        data-dfp-refresh-direct="${adRefreshDirect}"
      ></div>
    `;

    return adHTML;
  }

  isBrandSafe() {
    if (this.customVariables.brand_safety || this.customVariables.ad_policies) {
      return false;
    }
    return true;
  }

  generatePlaylist() {
    let customVideoPlaylistId = this.videoPlaylistID;
    if (this.videoPlaylistGamesList) {
      if (this.customVariables.games) {
        this.customVariables.games.forEach((gameTag) => {
          Array.from(Object.entries(this.videoPlaylistGamesList)).forEach((game) => {
            if(game[0] == gameTag) {
              customVideoPlaylistId = game[1];
            }
          });
        });
      }
    }

    const bbwPlaylistWrapper = document.createElement('figure');
    bbwPlaylistWrapper.classList = 'bbw_playlist video';
    bbwPlaylistWrapper.style.backgroundColor = '#f1f5f7';
    bbwPlaylistWrapper.style.padding = '1px 16px 1px';
    const bbwPlaylistHeader = document.createElement('p');
    bbwPlaylistHeader.className = 'section_title';
    bbwPlaylistHeader.textContent = this.videoPlaylistTitle;
    bbwPlaylistWrapper.appendChild(bbwPlaylistHeader);
    const bbwPlaylistVideoContainer = document.createElement('div');
    bbwPlaylistVideoContainer.classList = 'video-container';
    const bbwPlaylist = document.createElement('script');
    bbwPlaylist.async = true;
    bbwPlaylist.type = 'text/javascript';
    bbwPlaylist.src = `https://${  this.bbwPublication  }.bbvms.com/p/${  this.bbwAutoplay  }/l/${  customVideoPlaylistId  }.js`;
    bbwPlaylistVideoContainer.appendChild(bbwPlaylist);
    bbwPlaylistWrapper.appendChild(bbwPlaylistVideoContainer);
    return bbwPlaylistWrapper;
  }

  sendAnalyticsEvent(action, name) {
    if ('sendBrockmanAnalyticsEvent' in window)
      window.sendBrockmanAnalyticsEvent('poll', action, name);
  };

  addPrimisKeyValues(adVariables) {
    const { games, tags } = adVariables || {};
    let keyValues = '';

    if (games) {
      keyValues += `&kv_2=${games.join(',')}`;
    }

    if (tags) {
      keyValues += `&kv_1=${tags.join(',')}`;
    }

    return keyValues;
  }

  generatePrimisPlaylist() {
    const cacheBuster = Math.round(new Date().getTime() / 1000);
    const pubUrl = this.baseUri;
    const primisPlacement = this.primisPlaylistPlacementId;

    const primisKeyValues = this.addPrimisKeyValues(this.customVariables);

    const scriptEl = document.createElement('script');
    scriptEl.setAttribute('type', 'text/javascript');
    scriptEl.setAttribute('language', 'javascript');

    if (!this.ownedSite && this.childPublisher) {
      scriptEl.src = `https://live.primis.tech/live/liveView.php?s=${primisPlacement}&playerApiId=primis_playlist_commercial_slot&pubUrl=${pubUrl}&schain=1.0,1!gamer.network,${this.childPublisher},1&cbuster=${cacheBuster}${primisKeyValues}`;
    } else {
      scriptEl.src = `https://live.primis.tech/live/liveView.php?s=${primisPlacement}&playerApiId=primis_playlist_commercial_slot&pubUrl=${pubUrl}&cbuster=${cacheBuster}${primisKeyValues}`;
    }


    const primisWrapper = document.createElement('div');
    primisWrapper.classList = 'primis_wrapper';
    primisWrapper.appendChild(scriptEl);

    const playlistWrapper = document.createElement('figure');
    playlistWrapper.classList = 'primis_playlist_wrapper';
    playlistWrapper.style.clear = 'both';

    if(this.primisStyling) {
      playlistWrapper.style.backgroundColor = '#f1f5f7';
      playlistWrapper.style.padding = '1px 16px 16px 16px';
      const playlistTitle = document.createElement('p');
      playlistTitle.className = 'section_title';
      playlistTitle.textContent = this.videoPlaylistTitle;
      playlistWrapper.appendChild(playlistTitle);
    }

    playlistWrapper.appendChild(primisWrapper);

    return playlistWrapper;
  }

  generateSurvey(surveyElement) {
    const quokksterSurvey = document.querySelector('.poll_wrapper[data-fixed="false"]');
    // Add Quokkster poll if it's enabled and allowed on this article
    if(this.runQuokkster && this.articleAutoPoll) {
      if(quokksterSurvey) {
        surveyElement.append(quokksterSurvey);
        quokksterSurvey.dataset.placed = true;
        if (this.debug) {
          console.log(`%cADDING QUOKKSTER POLL`, 'background: none; color: green; padding: 4px 8px');
        }
      }
    } else {
      // Add Apester poll and hide any Quokkster placeholder
      if(quokksterSurvey) {
        quokksterSurvey.style.display = "none";
      }
      if (this.testingCohort.includes('testApester') || this.testingCohort.includes('testQuokkster')) {
        this.sendAnalyticsEvent('apester', 'loaded');
      }
      surveyElement.innerHTML = this.generateApester();
      if (this.debug) {
        console.log(`%cADDING APESTER POLL`, 'background: none; color: green; padding: 4px 8px');
      }
    }
  }

  generateApester() {
    const adHTML = `
      <div class="inlinead_lazyload apester_block">
        <div
          class="apester-media"
          data-token="${this.apesterToken}"
          data-context="true"
          data-tags=""
          data-fallback="true"
          height="${this.device === 'mobile' ? 810 : 890}"
        ></div>
      </div>
    `;

    window.addEventListener('message', event => {
      if (event.data.type === 'picked_answer') {
          const apesterEvent = event.data;
          permutive.track('ApesterSurveyResponse', {
              answer: {
                  id: apesterEvent.data.answerId,
                  text: apesterEvent.data.answerText
              },
              interaction: {
                  id: apesterEvent.data.interactionId
              },
              slide: {
                  id: apesterEvent.data.slideId,
                  title: apesterEvent.data.slideTitle
              }
          });

          this.sendAnalyticsEvent(apesterEvent.data.slideTitle, `${apesterEvent.data.slideTitle}: ${apesterEvent.data.answerText}`);
      }
    });

    return adHTML;
  }

  generateInread() {
    const adHTML = `
      <span class="advert_label">${this.advertHeader}</span>
      <div class="inreadvideo"></div>
    `;
    return adHTML;
  }

  addBBWScript(container) {
    const bbw_script = document.createElement('script');
    bbw_script.type = 'text/javascript';
    bbw_script.src = `https://gamernetwork.mainroll.com/a/${this.mobileInReadId}.js`;
    container.appendChild(bbw_script);
  }

  removeOverflowingSidebarAds() {
    const resizeObserver = new ResizeObserver(entries => {
      entries.forEach((entry) => {
        const autoads = document.querySelectorAll('.gn_sidebar > .autoad.collapsible');

        [...autoads].reduce((acc, cur) => {
          const cumulativeHeight = acc + cur.offsetHeight;

          if (cumulativeHeight > entry.contentRect.height) {
            const removeSlot = googletag.pubads().getSlots()
              .filter((slot) => slot.getSlotElementId() === cur.querySelector('.autoad_lazyloaded, .autoad_lazyload').id);
            googletag.destroySlots(removeSlot);
            cur.remove();
          }

          return cumulativeHeight
        }, 0);

        const emptySpaceLeft = this.checkEmptySpace(this.insertInto);

        if (emptySpaceLeft) {
          this.appendAd(emptySpaceLeft);
        }
      });
    });

    googletag.cmd.push(() => {
      resizeObserver.observe(document.querySelector('.gn_sidebar'));
    });
  }

  appendAd(emptySpaceLeft) {
    this.insertCounter++;

    if (!this.appendSidebarAds && this.insertCounter > 1) {
      return;
    }

    const adArea = document.querySelector(this.insertInto);
    const newAd = document.createElement('div');
    newAd.classList.add('autoad', 'collapsible');

    if (this.insertCounter == 1) {
      newAd.style.marginTop = this.insertTopMargin;
    }

    let columnHeight;

    if (this.stickySidebar && emptySpaceLeft >= window.innerHeight * this.stickyViewports) {
      columnHeight = window.innerHeight * this.stickyViewports;
    } else {
      columnHeight = Math.min(emptySpaceLeft, window.innerHeight + 600);
    }

    if (this.appendSidebarAds) {
      newAd.style.height = `${columnHeight}px`;
    } else {
      document.querySelector(this.insertInto).style.display = 'flex';
      document.querySelector(this.insertInto).style.flexDirection = 'column';
      newAd.style.height = '100%';
    }

    let lastAd = false;

    if (columnHeight < window.innerHeight + 600 || !this.appendSidebarAds) {
      lastAd = true;
    }

    newAd.innerHTML = this.generateAd(this.insertCounter);

    if (this.stickySidebar && columnHeight >= window.innerHeight + 600) {
      const sidebarAd = newAd.querySelector('.insert_ad_column');
      sidebarAd.style.position = 'sticky';
      sidebarAd.style.top = '260px';
      sidebarAd.style.paddingTop = '10px';

      // Wrap desktop bbw player and first sidebar ad
      if (this.insertCounter === 1) {
        const adWrapper = document.createElement('div');
        const bbwContainer = `<div style="width: 288px; height: 162px;" id="bbvms_sticky_video"></div>`;
        adWrapper.classList.add('bbvms_sticky_wrapper');
        adWrapper.style.cssText = `
          padding: 6px;
          width: 300px;
          background: white;
          border-radius: 3px;
          min-height: 200px;
        `;
        adWrapper.insertAdjacentHTML('afterbegin', bbwContainer);
        adArea.appendChild(adWrapper);
      }

      if (!lastAd) {
        sidebarAd.style.paddingBottom = '300px';
      }
    }

    adArea.appendChild(newAd);

    this.observer_autoads.observe(newAd.querySelector('.autoad_lazyload'));
  }

  checkEmptySpace(parentWrapper) {
    const adArea = document.querySelector(parentWrapper);
    const adAreaStyle = window.getComputedStyle(adArea);
    let adAreaFree = parseFloat(adAreaStyle.height) - parseFloat(adAreaStyle.marginTop);

    for (let i = 0; i < adArea.children.length; i++) {
      const style = adArea.children[i].currentStyle || window.getComputedStyle(adArea.children[i]);
      const margin = parseFloat(style.marginTop) + parseFloat(style.marginBottom);
      adAreaFree = adAreaFree - adArea.children[i].offsetHeight - margin;
    }

    if (adAreaFree > 700) {
      return adAreaFree;
    }
    return false;
  }

  insertAds() {
    const config = {
      rootMargin: `0px 0px ${this.lazyloadDefaultSpacer}px 0px`,
      threshold: 0,
    };

    if ('IntersectionObserver' in window) {
      const onAutoAd = (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.observer_autoads.unobserve(entry.target);
            const autoad = entry.target;
            this.requestAd(autoad, entry.target.id);
            autoad.classList.remove('autoad_lazyload');
            autoad.classList.add('autoad_lazyloaded');
            if (this.debug) {
              console.log(`%cClassic lazyload (${this.lazyloadDefaultSpacer}px) for: ${autoad.id}`, 'background: none; color: green; padding: 4px 8px');
            }
          }
        });
      };
      this.observer_autoads = new IntersectionObserver(onAutoAd, config);
    }

    const emptySpaceLeft = this.checkEmptySpace(this.insertInto);

    if (emptySpaceLeft) {
      this.appendAd(emptySpaceLeft);
    }
  }

  insertAfter(el, referenceNode) {
    referenceNode.parentNode.insertBefore(el, referenceNode.nextSibling);
  }

  elemDims(el) {
    return {
      top: el.offsetTop,
      bottom: el.offsetTop + el.offsetHeight,
      height: el.offsetHeight,
      element: el,
    };
  }

  paragraphPlacementCheck(i, array) {
    const isDesktop = this.device === 'desktop' || this.device === 'hd';
    const pArray = Array.from(array).filter((el) => el.tagName === 'P');
    const lastPossiblePlacement = pArray[pArray.length - 2];

    if (isDesktop && array[i] === lastPossiblePlacement) {
      return false;
    }

    if (i < array.length - 1) {
      return array[i].tagName === 'P' && array[i + 1].tagName === 'P';
    }

    return false;
  }

  inlineAdsDesktop() {
    let offsetPixelCount = 0;

    if ('IntersectionObserver' in window) {
      const config = {
        rootMargin: `0px 0px ${this.lazyloadDefaultSpacer}px 0px`,
        threshold: 0,
      };
      const onInlineAd = (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.observer_inlineads.unobserve(entry.target);
            const inlinead = entry.target;
            this.requestAd(inlinead);
            inlinead.classList.remove('inlinead_lazyload');
            inlinead.classList.add('inlinead_lazyloaded');
            if (this.debug) {
              console.log(`%cClassic lazyload (${this.lazyloadDefaultSpacer}px) for: ${inlinead.id}`, 'background: none; color: green; padding: 4px 8px');
            }
          }
        });
      };
      this.observer_inlineads = new IntersectionObserver(onInlineAd, config);
    }

    const newAd = (inlineCounter) => {
      const ad = document.createElement('div');
      ad.classList.add('inlinead', 'collapsible');
      ad.innerHTML = this.generateInlineAd(inlineCounter);

      if (ad.querySelector('.inlinead_lazyload')) {
        this.observer_inlineads.observe(ad.querySelector('.inlinead_lazyload'));
      }

      offsetPixelCount = 0;
      this.inlineCounter += 1;
      return ad;
    };

    const inlineInto = this.desktopInlineInto;
    const articleContentDesktop = () => {
      const els = document.querySelectorAll(`${inlineInto} > *`);
      return els;
    };

    const viewPortHeight = window.innerHeight * this.viewportMultiplierDesktop;
    articleContentDesktop().forEach((el, i, array) => {
      const isBetweenParagraph = this.paragraphPlacementCheck(i, array);
      offsetPixelCount += el.offsetHeight + 48;

      if (offsetPixelCount > viewPortHeight && isBetweenParagraph) {
        const ad = newAd(this.inlineCounter);
        this.insertAfter(ad, el);
      }
    });
  }

  inlineAds() {
    this.inlineCounter += 1;

    const viewPortHeight = window.innerHeight * this.viewportMultiplierMobile;
    const { includeHeaderInCalc } = this.inlineAdsConfig;
    const aricleContentOffset = includeHeaderInCalc
      ? document.querySelector(this.inlineInto).getBoundingClientRect().top + document.documentElement.scrollTop
      : 0;
    const minAdvertSpacing =
      this.inlineAdsConfig.minAdvertSpacing > 0 ? this.inlineAdsConfig.minAdvertSpacing : viewPortHeight;
    const { lastAdTollerance } = this.inlineAdsConfig ? this.inlineAdsConfig : 1;

    let offsetPixelCount = 0;

    if ('IntersectionObserver' in window) {
      const config = {
        rootMargin: `0px 0px ${this.lazyloadDefaultSpacer}px 0px`,
        threshold: 0,
      };
      const onInlineAd = (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.observer_inlineads.unobserve(entry.target);
            const inlinead = entry.target;
            this.requestAd(inlinead);
            inlinead.classList.remove('inlinead_lazyload');
            inlinead.classList.add('inlinead_lazyloaded');
            if (this.debug) {
              console.log(`%cClassic lazyload (${this.lazyloadDefaultSpacer}px) for: ${inlinead.id}`, 'background: none; color: green; padding: 4px 8px');
            }
          }
        });
      };
      this.observer_inlineads = new IntersectionObserver(onInlineAd, config);
    }

    const playlistConditionsMet = this.videoPlaylistConditions();
    const surveyConditionsMet = this.surveyConditions(false);

    const newAd = (inlineCounter) => {
      const ad = document.createElement('div');
      ad.classList.add('inlinead');

      if (inlineCounter === 1) {
        ad.innerHTML = this.generateInlineAd(inlineCounter);
      } else {
        ad.classList.add('collapsible');
        const inreadConditionsMet = this.inreadConditions(inlineCounter);

        if (inreadConditionsMet) {
          ad.innerHTML = this.generateInread();
          this.addBBWScript(ad.querySelector('.inreadvideo'));
        } else if (inlineCounter === 2) {
          if (playlistConditionsMet) {
            ad.appendChild(this.primisPlaylistPlacementId ? this.generatePrimisPlaylist() : this.generatePlaylist());
          } else {
            this.inlineCounter += 1;
            return null;
          }
        } else if (inlineCounter === 3) {
          if (surveyConditionsMet) {
            this.generateSurvey(ad);
          } else {
            ad.innerHTML = this.generateInlineAd(inlineCounter);
          }
        } else if (inlineCounter > 3) {
          ad.innerHTML = this.generateInlineAd(inlineCounter);
        }
      }

      if (ad.querySelector('.inlinead_lazyload')) {
        this.observer_inlineads.observe(ad.querySelector('.inlinead_lazyload'));
      }

      offsetPixelCount = 0;
      this.inlineCounter += 1;
      return ad;
    };

    const articleContents = () => {
      if (this.inlineBetweenTextOnly) {
        return document.querySelectorAll(
          `${this.inlineInto} > *, ${this.inlineInto} section > *`,
        );
      }
      // Check if content has <section> tags within body. If so, then use elements in them for ad placement, but not .summary or child <section>
      if (document.querySelectorAll(`${this.inlineInto} > section:not(.summary):not(.synopsis)`).length > 0) {
        return document.querySelectorAll(
          `${this.inlineInto} > *:not(section):not(.review_rating):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(script):not(.groups):not(.supporter-article-promo):not(.df-promo):not(aside):not(.internal-link):not(.poll_wrapper):not(.poll_ad_container), ${this.inlineInto} section:not(.summary):not(.synopsis) > *:not(section):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(script):not(.groups):not(.supporter-article-promo):not(.df-promo):not(aside):not(.internal-link):not(.poll_wrapper):not(.poll_ad_container)`,
        );
      }
      return document.querySelectorAll(
        `${this.inlineInto} > *:not(section):not(.review_rating):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(script):not(.groups):not(.supporter-article-promo):not(.df-promo):not(aside):not(.internal-link):not(.poll_wrapper):not(.poll_ad_container)`,
      );
    };

    const textElementPlacementCheck = (i, array) => {
      if(!this.inlineBetweenTextOnly) {
        return true;
      }
      const textElements = ["P","OL","UL","TABLE"];
      if (i < array.length - 1) {
        return textElements.includes(array[i].tagName) && textElements.includes(array[i + 1].tagName);
      }
      return false;
    };

    const bbwAndApesterPlacementCheck = (i, array) => {
      if (!this.inlineAvoidBbwApester) {
        return true;
      }

      if (i < array.length - 1) {
        const currentElement = array[i];
        const nextElement = array[i + 1];

        const selector = '.blue_billywig, .apester-media, .apester-unit';
        const mergedNodes = [...currentElement.querySelectorAll(selector), ...nextElement.querySelectorAll(selector)];

        return !mergedNodes.length > 0;
      }

      return false;
    };

    articleContents().forEach((contentElement, i, array) => {
      const canBePlacedHere = textElementPlacementCheck(i, array) && bbwAndApesterPlacementCheck(i, array);
      offsetPixelCount += contentElement.offsetHeight;

      if(canBePlacedHere) {
        if (offsetPixelCount >= viewPortHeight - aricleContentOffset && this.inlineCounter === 1) {
          // Inserts the first ad on the first element that is past the viewports height
          const ad = newAd(this.inlineCounter);
          this.insertAfter(ad, contentElement);
        } else if (this.inlineCounter === 2) {
          const pElAfterFishbowl = document.querySelector('.inlinead:not(.collapsible) + p');
          if (pElAfterFishbowl !== contentElement) {
            const ad = newAd(this.inlineCounter);
            if (ad) {
              this.insertAfter(ad, contentElement);
            }
          }
        } else if (offsetPixelCount >= minAdvertSpacing && this.inlineCounter > 2) {
          // Inserts adds after every collection of elements that adds to the minAdvertSpacing
          const ad = newAd(this.inlineCounter);
          this.insertAfter(ad, contentElement);
        } else if (
          (array.length > 2 && array.length === i + 2 && offsetPixelCount >= minAdvertSpacing * lastAdTollerance) ||
          (array.length > 2 && array.length === i + 2 && this.inlineCounter === 1) ||
          (array.length <= 2 && array.length === i + 1 && this.inlineCounter === 1)
        ) {
          // Inserts an add at the end of the content (before the last element) if there is a fair bit of space (indicated by lastAdTollerance)
          // Also inserts an ad (before the last element) if there are none on the page and we've reached the end of the content
          // Or at the very end if there is less than 2 elements.
          const ad = newAd(this.inlineCounter);
          this.insertAfter(ad, contentElement);
        }
      }

    });
  }

  inlineComUnitDesktop() {
    const renderPlaylist = this.videoPlaylistConditions();
    const renderSurvey = this.surveyConditions(true);

    if (!renderPlaylist && !renderSurvey) {
      if (this.debug) {
        console.log(`%cNO COMMERCIAL INJECTION FOR DESKTOP`, 'background: none; color: red; padding: 4px 8px');
      }
      return;
    }

    // Look at making this global rather than re-used.
    const articleContents = () => {
      const notSelector = '*:not(section):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(script):not(.groups):not(.supporter-article-promo):not(.df-promo):not(aside):not(.internal-link):not(figure.right + *):not(figure.right + * + *):not(figure.right + * + * + *):not(figure.left + *):not(figure.left + * + *):not(figure.left + * + * + *)';
      // Check if content has <section> tags within body. If so, then use elements in them for ad placement, but not .summary or child <section>
      if (document.querySelectorAll(`${this.desktopInlineInto} > section:not(.summary):not(.synopsis)`).length > 0) {
        return document.querySelectorAll(
          `${this.desktopInlineInto} > ${notSelector}, ${this.desktopInlineInto} section:not(.summary):not(.synopsis) > ${notSelector}`,
        );
      }
      return document.querySelectorAll(
        `${this.desktopInlineInto} > ${notSelector}`,
      );
    };

    const articleContentsArray = articleContents();

    const getInsertPoints = (array) => {
      const insertPoints = [];
      let paragraphCount = 0;
      let previousInsertPoint = 0;

      array.forEach((contentElement, i) => {
        const isBetweenParagraph = this.paragraphPlacementCheck(i, array);

        if (contentElement.tagName === 'P') {
          paragraphCount += 1;
        }

        if (
          isBetweenParagraph &&
          contentElement.getBoundingClientRect().top + document.body.scrollTop - previousInsertPoint >
            window.innerHeight &&
          paragraphCount > 2
        ) {
          previousInsertPoint = contentElement.getBoundingClientRect().top + document.body.scrollTop;
          insertPoints.push(contentElement);
        }
      });

      return insertPoints;
    };

    const slot1 = document.createElement('div');
    const slot2 = document.createElement('div');
    slot1.classList.add('commercial-slot-1');
    slot2.classList.add('commercial-slot-2');

    let slot1InsertPoint;
    let slot2InsertPoint;

    getInsertPoints(articleContentsArray).forEach((insertPoint, i) => {
      if (i === 0) {
        slot1InsertPoint = insertPoint;
      } else if (i === 1) {
        slot2InsertPoint = insertPoint;
      }
    });

    if (renderPlaylist && renderSurvey) {
      if (slot1InsertPoint) {
        slot1InsertPoint.parentNode.insertBefore(slot1, slot1InsertPoint.nextSibling);
        slot1.appendChild(this.primisPlaylistPlacementId ? this.generatePrimisPlaylist() : this.generatePlaylist());
        if (this.debug) {
          console.log(`%cADD SLOT 1 AFTER: `, 'background: none; color: green; padding: 4px 8px', slot1InsertPoint);
          console.log(`%cADDING ${this.primisPlaylistPlacementId ? 'PRIMIS' : 'BBW'} PLAYLIST TO SLOT 1`, 'background: none; color: green; padding: 4px 8px');
        }
      }
      if (slot2InsertPoint) {
        slot2InsertPoint.parentNode.insertBefore(slot2, slot2InsertPoint.nextSibling);
        this.generateSurvey(slot2);
        if (this.debug) {
          console.log(`%cADD SLOT 2 AFTER: `, 'background: none; color: green; padding: 4px 8px', slot2InsertPoint);
          console.log(`%cADDING POLL TO SLOT 2`, 'background: none; color: green; padding: 4px 8px');
        }
      }
    } else if (renderPlaylist && !renderSurvey && slot1InsertPoint) {
      slot1InsertPoint.parentNode.insertBefore(slot1, slot1InsertPoint.nextSibling);
      slot1.appendChild(this.primisPlaylistPlacementId ? this.generatePrimisPlaylist() : this.generatePlaylist());
      if (this.debug) {
        console.log(`%cADD SLOT 1 AFTER: `, 'background: none; color: green; padding: 4px 8px', slot1InsertPoint);
        console.log(`%cADDING ${this.primisPlaylistPlacementId ? 'PRIMIS' : 'BBW'} PLAYLIST TO SLOT 1`, 'background: none; color: green; padding: 4px 8px');
        console.log(`%cNO POLL`, 'background: none; color: red; padding: 4px 8px');
      }
    } else if (!renderPlaylist && renderSurvey  && slot1InsertPoint) {
      slot1InsertPoint.parentNode.insertBefore(slot1, slot1InsertPoint.nextSibling);
      this.generateSurvey(slot1);
      if (this.debug) {
        console.log(`%cADD SLOT 1 AFTER: `, 'background: none; color: green; padding: 4px 8px', slot1InsertPoint);
        console.log(`%cADDING POLL TO SLOT 1`, 'background: none; color: green; padding: 4px 8px');
        console.log(`%cNO PLAYLIST`, 'background: none; color: red; padding: 4px 8px');
      }
    } else if (!slot1InsertPoint && !slot2InsertPoint) {
      if (this.debug) {
        console.log(`%cNO SLOT 1/SLOT 2 ADDED`, 'background: none; color: red; padding: 4px 8px');
      }
    }
  }

  freshAd(id) {
    const config = {
      rootMargin: `0px 0px ${this.lazyloadDefaultSpacer}px 0px`,
      threshold: 0,
    };

    if ('IntersectionObserver' in window) {
      const onFreshAd = (entries) => {
        entries.forEach((entry) => {
          if (entry.intersectionRatio > 0) {
            this.observer_fresh.unobserve(entry.target);
            const freshad = entry.target;
            this.requestAd(freshad);
            freshad.classList.remove('freshad_lazyload');
            freshad.classList.add('freshad_lazyloaded');
            if (this.debug) {
              console.log(`%cClassic lazyload (${this.lazyloadDefaultSpacer}px) for: ${freshad.id}`, 'background: none; color: green; padding: 4px 8px');
            }
          }
        });
      };
      if (!this.observer_fresh) {
        this.observer_fresh = new IntersectionObserver(onFreshAd, config);
      }
    }

    const check_fresh_ad = document.querySelector(id);

    if (check_fresh_ad !== null) {
      this.observer_fresh.observe(document.querySelector(id));
    }
  }

  appendStickyAd(stickyAdCode, stickySizes) {
    const stickyAd = document.createElement('div');
    stickyAd.id = 'sticky_leaderboard';
    stickyAd.classList.add('collapsible');
    stickyAd.innerHTML = `
      <div class="leaderboard_inner">
        <div class="${this.adClass} sticky_lb"
          data-dfp-id="${stickyAdCode}"
          data-dfp-sizes="${stickySizes}"
          data-dfp-targeting="lazyload=false,sticky=true"
          data-dfp-responsive=""
          data-dfp-collapse="true"
          id="div-ga-${stickyAdCode}"
        ></div>
      </div>
      <div id="sticky_leader_close" class="button">
        <span class="advert_label">${this.advertHeader}</span>
        <span class="icon" style="--icon-mask-image: url('/static/solid/xmark.svg')"></span>
      </div>
    `;

    document.body.appendChild(stickyAd);

    const stickyClose = stickyAd.querySelector('#sticky_leader_close');
    stickyClose.addEventListener(
      'click',
      () => {
        stickyAd.remove();
      },
      false,
    );
  }

  registerDesktopBillboard() {
    if (this.device !== 'hd') return;

    window.googletag.cmd.push(() => {
      const onVisibilityChange = (event, adUnit, adUnitContainer, timerId) => {
        const { slot } = event;
        const slotEl = document.getElementById(slot.getSlotElementId());
        if (slotEl === adUnit && event.inViewPercentage === 0) {
          if (timerId) {
            clearTimeout(timerId);
          }
          adUnitContainer.classList.remove('sticky-top-leaderboard');
          adUnit.style.position = 'unset';
          adUnitContainer.style.height = `${adUnit.offsetHeight}px`;
          window.googletag
            .pubads()
            .removeEventListener('slotVisibilityChanged', onVisibilityChange);
        }
      };

      window.googletag.pubads().addEventListener('slotOnload', (event) => {
        const { slot } = event;
        const slotEL = document.getElementById(slot.getSlotElementId());
        const container = document.querySelector('.fixed-billboard');
        const leaderboard =
          container && container.querySelector('.leaderboard');
        const stickyContainer =
          container && container.querySelector('.leaderboard > div');

        if (leaderboard && slotEL === leaderboard) {
          const slotIframe = slotEL.querySelector('iframe');
          const target =
            document.querySelector('.article_header') ||
            document.querySelector('.spotlight');

          if (target) {
            const contentWrapper = document.querySelector('#content');
            stickyContainer.style.height = `${contentWrapper.offsetHeight}px`;
            leaderboard.style.position = 'sticky';
            leaderboard.style.height = `${slotIframe.offsetHeight}px`;
            const leaderboardContainer = document.querySelector('.leaderboard_container');
            const minPos =
              (target.getBoundingClientRect().bottom + window.scrollY) -
              (leaderboardContainer.getBoundingClientRect().bottom + window.scrollY) +
              leaderboard.offsetHeight;

            stickyContainer.classList.add('sticky-top-leaderboard');

            const timerId = setTimeout(() => {
              const scrollHeight = window.scrollY;
              const visibilityHeight =
                slotIframe.getBoundingClientRect().bottom +
                window.scrollY -
                (stickyContainer.getBoundingClientRect().top + window.scrollY);
              if (scrollHeight > minPos) {
                stickyContainer.style.height = `${visibilityHeight + 250}px`;
              } else {
                stickyContainer.style.height = `${minPos}px`;
              }
            }, 2000);

            window.googletag
              .pubads()
              .addEventListener('slotVisibilityChanged', (e) =>
                onVisibilityChange(e, leaderboard, stickyContainer, timerId),
              );
          }
        }
      });
    });
  }
}
